.banner {
  background: linear-gradient(
    90deg,
    rgba(12, 76, 138, 1) 0%,
    rgb(0, 99, 199) 100%
  );
}

.banner h1 {
  color: white;
}

.banner .container {
  display: flex;
  align-items: center;
}

.banner .container img {
  width: 5em;
  margin-right: 4em;
}
