.login-card {
  display: flex;
  justify-content: center;
}

.login-card img {
  width: 5em;
}

.is-centered {
  margin: 3em;
  display: flex;
  flex-direction: column;
}
