.is-ancestor {
  padding: 50px;
}

.payment {
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.payment-card {
  box-shadow: 1px 1px 0.2em grey;
  border-radius: 1em;
  padding: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quantity-input {
  margin-bottom: 1em;
}

.ticket-options {
  display: flex;
  flex-direction: column;
}

.ticket-options button {
  margin: 0.5em 0;
  padding: 2em 0;
  font-weight: 100;
  font-size: 1.25em;
}

.container-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 125%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 200%;
}

#pdfDocument {
  z-index: 100000;
}

@media print {
  body * {
    display: none;
  }
  #pdfDocument {
    display: block;
    width: 27cm;
    height: 20cm;
  }
}
