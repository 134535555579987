svg {
  width: 4.5em;
  height: 4.5em;
}

.columns {
  padding: 20px;
}

.card {
  padding: 8em;
}
