.finish_view {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: appearing 1s linear;
}

.finish_view .message {
  font-size: 5em;
  text-align: center;
  margin: 2em;
}

.finish_view .warning {
  color: red;
  text-align: center;
  font-size: 2em;
  width: 60%;
  font-weight: 900;
  animation: blink 2.5s linear infinite;
}

@keyframes appearing {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
